body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f7fc;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

[data-testid="right-icons"] {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  margin-right: 10px;
}

@media only screen and (max-width: 960px) {
  [data-testid="right-icons"] {
    justify-content: center;
    margin-right: 10px;
  }
}
